import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PrivateParking = () => {
    return (
        <Layout>
            <Seo
                title="Inicio - Parking - BIKESAFE"
                description="Cómo guardar las bicicletas eficazmente y sacar el máximo provecho del espacio no utilizado. Nuestros racks para guardar bicicletas en casa protegen su bicicleta, sus paredes y sus vehículos. Tanto si quiere un sistema para guardar bicicletas en garajes, en casa, en un cobertizo o en el jardín, nosotros disponemos de una solución. Aproveche al máximo la superficie del suelo o de las paredes con uno de nuestros portabicicletas/racks/armarios."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Inicio - Parking
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Cómo guardar las bicicletas eficazmente y sacar el máximo provecho del espacio no utilizado. Nuestros racks para guardar bicicletas en casa protegen su bicicleta, sus paredes y sus vehículos. Tanto si quiere un sistema para guardar bicicletas en garajes, en casa, en un cobertizo o en el jardín, nosotros disponemos de una solución. Aproveche al máximo la superficie del suelo o de las paredes con uno de nuestros portabicicletas/racks/armarios.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/sheffield.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Quedamos encantados con el amable y eficaz servicio que nos prestó Bike Safe. Instalaron un portabicicletas Sheffield delante de nuestro jardín a los pocos días de ponernos en contacto con ellos. El portabicicletas tiene un aspecto magnífico y ofrece un lugar cómodo para guardar nuestras bicicletas de forma segura sin obstaculizar la entrada.""
                                <strong> Emlyn Lamburn, propietario de una vivienda, Londres</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PrivateParking;